import React from 'react';
import { getUserLangKey } from 'ptz-i18n';
import { withPrefix } from "gatsby-link";
import { pageMap } from '../data/page-map';
import { graphql } from 'gatsby';

class RedirectPhotos extends React.PureComponent {
  constructor(args) {
    super(args);

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const { langs, defaultLangKey } = args.data.site.siteMetadata.languages;
      const langKey = getUserLangKey(langs, defaultLangKey);
      const pressKitUrl = withPrefix(`/${langKey}/${pageMap['press-kit'][langKey]}`);

      window.___history.replace(pressKitUrl);
    }
  }

  render() {
    return (<div />);
  }
}

export default RedirectPhotos;

export const pageQuery = graphql`
  query PhotosQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
